<script lang="ts" context="module">
    import cn from 'classnames';
    let onTop: any;
    const modals: any = [''];
    let graphUrl: string = '';
    
    export function getModal(_graphUrl: string) {
        graphUrl = _graphUrl;
        return modals[''];
    }
</script>

<script lang="ts">
    import { onDestroy } from 'svelte';
    import Close from '$lib/images/svg/close.svelte';
    
    let topDiv: any;
    let visible = false;
    let prevOnTop: any;
    let closeCallback: any;
    let graphUrlToModal: string = '';
    
    export let id = '';
    
    function open(callback: any) {
        closeCallback = callback;
        if (visible) return;
        prevOnTop = onTop;
        onTop = topDiv;
        document.body.style.overflow = 'hidden';
        visible = true;
        graphUrlToModal = graphUrl;
    }

    function close(retVal: any) {
        console.log("idk")
        onTop = prevOnTop;
        if (onTop == null) document.body.style.overflow = '';
        visible = false;
        if (closeCallback) closeCallback(retVal);
    }

    modals[id] = { open, close };
    
    onDestroy(() => {
        delete modals[id];
    });
</script>

<div
        class={cn(
		'fixed left-1/2 top-1/2 z-[1000] flex max-h-[calc(100vh-1.25rem)] w-[calc(100vw-1.25rem)] max-w-[60.625rem] -translate-x-1/2 -translate-y-1/2 flex-col bg-white shadow-sm transition-transform md:shadow-custom',
		visible ? 'visible' : 'invisible'
	)}
>
    <div class="relative w-full bg-white rounded-lg shadow-lg p-6 mb-5">
        <button
                on:click={() => close(null)}
                on:keydown
                on:keypress
                on:keyup
                class="text-gray-lighter4 absolute right-0 top-0 cursor-pointer p-2 transition-transform hover:scale-125 md:p-4"
        >
            <Close class="h-4 w-4" />
        </button>
        <img src={graphUrlToModal} alt="Graf" class="w-full" />
    </div>
</div>
<div
        class={cn(
		'fixed bottom-0 left-0 right-0 top-0 z-[999] flex items-center justify-center bg-black bg-opacity-60',
		visible != null && visible === true ? 'visible' : 'invisible'
	)}
        on:click={() => close()}
        on:keydown
        on:keypress
        on:keyup
/>


